import { createContext, ReactChild, useState } from 'react';

type PublicContextType = {
  searchedTutor: SearchTutor.Root[];
  setSearchedTutors: (searchedTutor: SearchTutor.Root[]) => void;
  tutorProfile: Profile.Root;
  setTutorProfile: (tutorProfile: Profile.Root) => void;
};

const PublicContext = createContext(null as unknown as PublicContextType);

const PublicProvider = ({ children }: { children: ReactChild }) => {
  const [searchedTutor, setSearchedTutors] = useState<SearchTutor.Root[]>([]);
  const [tutorProfile, setTutorProfile] = useState<Profile.Root>(
    {} as Profile.Root
  );

  return (
    <PublicContext.Provider
      value={{
        searchedTutor,
        setSearchedTutors,
        tutorProfile,
        setTutorProfile,
      }}
    >
      {children}
    </PublicContext.Provider>
  );
};

export { PublicContext, PublicProvider };
