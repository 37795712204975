import type { AppProps } from 'next/app';
import NextNProgress from 'nextjs-progressbar';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { PublicProvider } from '../contexts/PublicContext';
import '../styles/globals.css';
import Head from 'next/head';

const theme = extendTheme({
  colors: {
    accent: {
      0: '#fff',
      50: '#ebeaf2',
      100: '#c2c0d7',
      200: '#9a96bd',
      300: '#716ca2',
      400: '#484287',
      500: '#342d7a',
      600: '#2f296e',
      700: '#2a2462',
      800: '#241f55',
    },
  },
  fontSizes: {
    '3xl': '1.5rem',
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css"
        />
      </Head>
      <ChakraProvider resetCSS={true} theme={theme}>
        <NextNProgress options={{ showSpinner: false }} />
        <PublicProvider>
          <Component {...pageProps} />
        </PublicProvider>
      </ChakraProvider>
    </>
  );
}

export default MyApp;
